import { ComputedRef } from 'vue'
import { RouteLocation } from 'vue-router'
interface ListItem {
  title: string;
  icon: string;
  iconPrefix?: 'fa' | 'van-icon';
  iconColor?: string;
  num?: number;
  to?: string | RouteLocation;
  hasPermission?: boolean;
}

interface MenuList {
  title?: string;
  items: ListItem[];
  authority: boolean | ComputedRef<boolean>;
}

const createListItem = ({
  title,
  icon,
  iconPrefix = 'fa',
  iconColor = 'rgb(251, 189, 8)',
  num = 0,
  to = '',
  hasPermission = true
}: ListItem): ListItem => ({
  title,
  icon,
  hasPermission,
  iconPrefix,
  iconColor,
  num,
  to
})

export {
  ListItem,
  createListItem,
  MenuList
}
