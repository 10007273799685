
import { createListItem } from '@/components/MomaiMenu/types'
import { user, applicants } from '@/states/login'
import { computed } from 'vue'

export default {

  setup: () => {
    const menu = computed(() => ({
      authority: true,
      items: [
        createListItem({
          icon: 'user-plus',
          title: '申请人管理',
          iconColor: 'rgb(243, 123, 29)',
          to: '/admin/user-list/applicants',
          num: applicants.value
        }),
        createListItem({
          icon: 'graduation-cap',
          title: '学员管理',
          iconColor: 'rgb(57, 181, 74)',
          to: '/admin/user-list/students'
        }),
        createListItem({
          icon: 'address-book',
          title: '导师管理',
          to: '/admin/user-list/teachers'
        }),
        createListItem({
          icon: 'users',
          title: '管理人员设置（超级管理员）',
          iconColor: 'rgb(229, 77, 66)',
          to: '/admin/user-list/admin',
          hasPermission: user.value.delFlag === '9'
        })
      ]
    }))
    return {
      menu
    }
  }
}
